<template>
  <div v-if="version === 'v1'" class="n-select n-sort-select w-100">
    <select
      v-model="filterCriteria.sort"
      class="p-3 w-100 n-select-box"
      :class="dynamicSelectClass"
      @change="onSort()"
    >
      <option
        v-for="(option, index) in sortOptions"
        :key="`sortOption-${index}`"
        :value="option.value"
        :class="
          filterCriteria.sort === option.value ? 'n-select-selected-option' : ''
        "
      >
        {{ option.title }}
      </option>
    </select>
  </div>
  <div
    v-else-if="version === 'v2'"
    class="properties-sortbox tw-w-full tw-max-w-52 tw-border tw-pr-2"
    style="border-radius: var(--rounded-input); border-color: var(--c-primary)"
  >
    <select
      v-model="filterCriteria.sort"
      class="tw-cursor-pointer tw-px-4"
      :class="dynamicSelectClass"
      @change="onSort()"
    >
      <option
        v-for="(option, index) in sortOptions"
        :key="`sortOption-${index}`"
        :value="option.value"
        :class="
          filterCriteria.sort === option.value ? 'n-select-selected-option' : ''
        "
      >
        {{ option.title }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { mapState } from 'pinia';
import Locations from '~/components/common/Locations.vue';
import MultipleInputGroup from '~/components/common/MultipleInputGroup.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { PFilterSortOptions } from '~/units/properties/types';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'LibraryPropertiesSortBox',
  components: { NcButton, MultipleInputGroup, Locations },

  props: {
    filterCriteria: {
      required: true,
      type: Object as PropType<{ sort: PFilterSortOptions }>,
    },

    version: {
      required: false,
      default: 'v1',
      type: String,
    },
  },

  emits: ['onSort', 'update:filterCriteria'],

  computed: {
    ...mapState(useWebsiteStore, ['theme']),

    dynamicSelectClass() {
      switch (this.theme) {
        case 'theme3':
          return 'tw-bg-[var(--isabelline)] tw-rounded-sm tw-border tw-border-[var(--camel)] tw-outline-none tw-tracking-normal tw-uppercase tw-font-semibold';
        case 'theme2':
          return 'tw-outline-none tw-uppercase tw-tracking-[2px]';
        default:
          return '';
      }
    },

    filterCriteriaModel: {
      get() {
        return this.filterCriteria;
      },
      set(value: PFilterSortOptions) {
        this.$emit('update:filterCriteria', value);
      },
    },

    sortOptions() {
      return [
        {
          title: 'Suggested',
          value: PFilterSortOptions.SUGGESTED,
        },
        {
          title: 'Highest Price',
          value: PFilterSortOptions.HIGHEST_PRICE,
        },
        {
          title: 'Lowest Price',
          value: PFilterSortOptions.LOWEST_PRICE,
        },
        {
          title: 'Newest Listed',
          value: PFilterSortOptions.NEWEST_LISTED,
        },
        {
          title: 'Oldest Listed',
          value: PFilterSortOptions.OLDEST_LISTED,
        },
      ];
    },
  },

  methods: {
    onSort() {
      this.$emit('onSort');
    },
  },
});
</script>

<style scoped>
.n-sort-select {
}

.n-sort-select:after {
  content: '\e92e';
  font-family: 'neuron-common-icons', sans-serif !important;
  font-size: 16px;
  color: var(--brown);
}
</style>
