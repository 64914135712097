<template>
  <GoogleMap
    :api-key="googleMapKey"
    style="width: 100%; height: 500px"
    :center="center"
    :zoom="15"
    :fullscreen-control="!isMarkersDraggable"
    :street-view-control="!isMarkersDraggable"
    :map-type-control="!isMarkersDraggable"
  >
    <template v-if="!isMarkersDraggable">
      <CustomMarker
        v-for="(marker, index) in markers"
        :key="`custom-marker-${index}`"
        :options="{
          position: markerLocationFilter(marker),
          anchorPoint: 'BOTTOM_CENTER',
        }"
      >
        <div
          v-if="variant === 'v1'"
          style="text-align: center"
          :style="markerColorFilter(marker)"
        >
          <img
            :src="markerImg"
            width="100%"
            height="100%"
            alt=""
            style="margin-top: 8px; max-width: 76px; max-height: 76px"
          />
        </div>

        <div v-else-if="variant === 'v2'" style="text-align: center">
          <div
            class="tw-flex tw-w-[230px] tw-max-w-[280px] tw-flex-col tw-gap-1 tw-border tw-bg-white tw-px-3 tw-py-4 tw-text-left"
            style="border-color: var(--purple)"
          >
            <library-text :text="marker.name" type="bold" class="!tw-text-xl" />
            <nuxt-link :href="`tel:${marker.phone}`">
              <library-text
                type="bold"
                :text="marker.phone"
                class="tw-text-base"
                style="color: var(--nc-mini-title-color)"
              />
            </nuxt-link>
            <nuxt-link
              :to="marker.button"
              :external="true"
              target="_blank"
              class="tw-pt-2 tw-text-sm"
              :class="{
                'pxp-search-properties-btn': marker.buttonType === 'primary',
                'pxp-primary-cta pxp-animate after:tw-border-inherit':
                  marker.buttonType === 'secondary',
              }"
              :style="`background-color: var(--${
                marker.buttonBackgroundColor
              }); color: var(--${marker.buttonTextColor}); ${
                marker.buttonType === 'secondary'
                  ? `border-top-color: var(--${marker.buttonTextColor});`
                  : ''
              }`"
            >
              {{ marker.buttonLabel }}
            </nuxt-link>
          </div>
        </div>
      </CustomMarker>
    </template>
    <template v-else-if="isMarkersDraggable">
      <Marker
        v-for="(marker, index) in markers"
        :key="`marker-${index}`"
        :options="{
          position: marker,
          anchorPoint: 'BOTTOM_CENTER',
          draggable: true,
        }"
        @dragend="onMarkerDragEnd"
      />
    </template>
  </GoogleMap>
</template>

<script lang="ts">
import { GoogleMap, Marker, CustomMarker } from 'vue3-google-map';
import type { PropType } from 'vue';
import { convertToFilter } from '~/units/core/providers/colors.provider';
import { debounce } from '~/units/core/providers/debounce.provider';
import { SubComponentMixin } from '~/mixins/subComponent.mixin';
import type { GoogleMapLocation } from '~/units/addresses/types';

/** draggable markers support was not implemented yet to use draggable maker, please use neuron google map component. **/

interface GoogleMapLocationWithSettings extends GoogleMapLocation {
  color: string;
}

export default defineNuxtComponent({
  name: 'NeuronGoogleMap',
  components: { GoogleMap, Marker, CustomMarker },

  props: {
    center: {
      required: true,
      type: Object as PropType<GoogleMapLocation>,
    },

    markers: {
      required: true,
      type: Array as PropType<GoogleMapLocationWithSettings[]>,
    },

    markerImg: {
      required: false,
      type: String,
      default: '/cdn/content/map-marker-alt.svg',
    },

    isMarkersDraggable: {
      required: false,
      type: Boolean,
      default: false,
    },

    variant: {
      required: false,
      type: String,
      default: 'v1',
    },
  },

  mixins: [SubComponentMixin],

  data() {
    return {
      handler: null,
    };
  },

  computed: {
    markersModel: {
      get() {
        return this.markers;
      },
      set(value: GoogleMapLocation) {
        this.$emit('update:markers', value);
      },
    },

    googleMapKey() {
      const nuxtApp = useNuxtApp();
      return nuxtApp.$config.public.GOOGLE_MAPS_KEY;
    },
  },

  mounted() {
    this.createHandler();
  },

  methods: {
    createHandler() {
      if (!this.handler && this.isMarkersDraggable) {
        this.handler = debounce(this.onPositionChanged, 500);
      }
    },

    onPositionChanged() {
      if (this.markers && this.isMarkersDraggable) {
        this.$emit('onPositionChanged');
      }
    },

    onMarkerDragEnd(e: any) {
      if (this.isMarkersDraggable) {
        const newPosition = { ...e.latLng };

        this.markersModel = {
          lat: newPosition.lat(),
          lng: newPosition.lng(),
        };

        this.handler();
      }
    },

    markerColorFilter(marker: GoogleMapLocationWithSettings) {
      if (marker.color) {
        return {
          '--primary-color-filter': `${convertToFilter(marker.color)}`,
        };
      }
    },

    markerLocationFilter(marker: any) {
      return {
        lat: marker.lat,
        lng: marker.lng,
      };
    },
  },
});
</script>

<style scoped>
:deep([src='/cdn/content/map-marker-alt.svg']) {
  filter: var(--primary-color-filter);
}
</style>
