<template>
  <div class="tw-flex tw-w-full tw-flex-col">
    <div
      v-for="(accordion, index) in elements"
      :key="index"
      class="border-bottom accordion-list tw-py-4"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-py-2"
        @click="toggleAccordion(index)"
      >
        <library-title :text="accordion.title" tag="h3"></library-title>
        <div class="tw-cursor-pointer">
          <svg
            v-if="activeIndex === index"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M5 12h14"></path>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M5 12h14"></path>
            <path d="M12 5v14"></path>
          </svg>
        </div>
      </div>
      <transition
        name="accordion"
        @enter="startTransition"
        @leave="endTransition"
        @after-leave="onAfterLeave"
        style="transition: height 0.5s ease-out; overflow: hidden"
        :style="`height: ${activeIndex === index ? 'auto' : 0}`"
      >
        <template v-if="accordion.description">
          <library-text
            @click="toggleAccordion(index)"
            v-show="activeIndex === index"
            tag="p"
            :html="accordion.description"
          >
          </library-text>
        </template>
        <template v-else>
          <div v-show="activeIndex === index">
            <slot name="description"></slot>
          </div>
        </template>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';

export default {
  name: 'LibraryAccordion',
  props: {
    elements: {
      required: false,
      type: Array as PropType<{ title: string; description: string }[]>,
      default: () => [],
    },
  },

  data() {
    return {
      activeIndex: 0,
    };
  },

  methods: {
    toggleAccordion(index: number) {
      if (this.activeIndex === index) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
      }
    },
    startTransition(el: any) {
      el.style.height = '0';
      el.style.opacity = '0';
      setTimeout(() => {
        el.style.height = el.scrollHeight + 'px';
        el.style.opacity = '1';
      }, 10);
    },
    endTransition(el: any) {
      el.style.height = el.scrollHeight + 'px';
      setTimeout(() => {
        el.style.height = '0';
        el.style.opacity = '0';
      }, 10);
    },
    onAfterLeave(el: any) {
      el.style.display = 'list-item';
    },
  },
};
</script>
