<template>
  <lazy-library-container
    v-if="variants.v1 === variant"
    class="tw-pt-[90px] md:tw-pt-[150px]"
  >
    <lazy-library-properties-search-box
      v-model:filters="filters"
      v-model:locations="locations"
      v-model:search-input="searchInput"
      :search-type="searchType"
      @on-search="onSearch()"
      @open-extended-popup="openExtendedPopup()"
    />
    <section
      v-if="filterSummary"
      class="py-4"
      :style="propertiesConfig.backgroundColor"
      style="border-bottom: 1px solid black; z-index: 40"
    >
      <div class="row g-0 d-flex justify-content-between align-items-center">
        <div class="col-12 col-lg-6">
          <lazy-library-text :text="filterSummary"></lazy-library-text>
        </div>
        <div
          class="col-12 col-lg-6 col-xl-3 mt-4 mt-lg-0 d-flex flex-row justify-content-lg-end align-items-center"
        >
          <div
            v-if="isApplicantExist"
            class="d-flex flex-row align-items-center n-cursor-pointer me-3"
            @click="onClickedProfile()"
          >
            <span class="nc-icon-profile-user-1 me-2" style="font-size: 24px">
            </span>
            <div class="text-capitalize text-truncate">
              {{ contactSession.name }}
            </div>
          </div>

          <nc-button
            v-if="!isApplicantExist"
            label="Setup Heads Up Alert"
            @on-click="setupHeadsUpAlerts()"
          />
          <nc-button
            v-if="isApplicantExist"
            label="Update Heads Up Alert"
            @on-click="updateHeadsUpAlerts()"
          />
        </div>
      </div>
    </section>
    <section
      v-if="isLoading"
      :style="propertiesConfig.backgroundColor"
      class="py-5"
    >
      <loader />
    </section>
    <section
      v-else-if="
        !isLoading && (highlightedProperties.length || properties.length)
      "
      class="pt-3 pb-5"
      :style="propertiesConfig.backgroundColor"
    >
      <div v-if="locations.length > 0" class="row g-0 mb-3">
        <div class="col-12">
          <location-boxes
            v-model:locations="locations"
            :is-first-location-excluded="true"
          />
        </div>
      </div>
      <template v-if="highlightedProperties.length">
        <div
          v-if="highlightedProperties.length > 0"
          class="row g-0 mb-5 d-flex flex-row justify-content-between"
        >
          <div class="col-md-8 col-12 tw-mb-4 md:tw-mb-0">
            <lazy-library-title
              tag="h1"
              :text="`New matches: ${newMatchesPropertyCount} properties`"
            >
            </lazy-library-title>
          </div>
          <div class="col-md-4 col-xl-2 col-12 d-flex justify-content-end">
            <lazy-library-properties-sort-box
              v-model:filter-criteria="filterCriteria"
              @on-sort="sort()"
            />
          </div>
        </div>
        <lazy-library-properties-page-list
          v-if="highlightedProperties.length > 0"
          :component="component"
          :properties="highlightedProperties"
        />
      </template>

      <div
        class="row g-0 ps-md-2 my-5 d-flex flex-row justify-content-between align-items-center"
      >
        <div class="col-md-6 col-lg-6 col-xl-8 col-12">
          <lazy-library-title
            v-if="highlightedProperties.length === 0"
            tag="h1"
            :text="`${count} properties`"
          >
          </lazy-library-title>
          <lazy-library-title
            v-else-if="highlightedProperties.length > 0 && properties.length"
            tag="h1"
            :text="`Existing matches: ${
              count - highlightedProperties.length
            } properties`"
          >
          </lazy-library-title>
        </div>
        <div
          class="col-md-4 col-lg-3 col-xl-2 col-12 mt-4 mt-md-0 d-flex justify-content-end"
        >
          <lazy-library-properties-sort-box
            v-if="highlightedProperties.length === 0"
            v-model:filter-criteria="filterCriteria"
            @on-sort="sort()"
          />
        </div>
      </div>
      <lazy-library-properties-page-list
        :component="component"
        :properties="properties"
      />
      <div v-if="isPropertiesGridLoadMoreActive" class="row g-0 mt-5">
        <div class="col-12 d-flex flex-row justify-content-center">
          <div
            class="n-cursor-pointer tw-rounded-sm tw-px-4 tw-py-3"
            style="border: 1px solid var(--camel)"
            @click="loadMore()"
          >
            <lazy-library-text
              type="bold"
              text="LOAD MORE PROPERTIES"
            ></lazy-library-text>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else-if="properties && properties.length === 0"
      class="py-5"
      :style="propertiesConfig.backgroundColor"
    >
      <lazy-theme2-base-not-found
        title="Unfortunately, there are no properties matching your search criteria."
      >
        <template #description>
          Please
          <nuxt-link to="/heads-up-alerts/register" :external="true"
            ><u class="unknown-user-text text"
              >setup a Heads Up property alert</u
            ></nuxt-link
          >
          to be kept up to date in the future with new suggested properties that
          are based around your specific criteria.
        </template>
      </lazy-theme2-base-not-found>
    </section>
    <advanced-search
      v-if="!isMobile && isExtendedFilterPopupVisible"
      v-model:filters="filters"
      v-model:is-available="searchInput.is_available"
      :search-type="searchType"
      @on-open-all-styles-popup="allStylesConfig.isActive = true"
    />

    <popup
      v-if="isMobile"
      :config="advancedSearchConfig"
      custom-width="auto"
      :custom-wrapper-class="
        propertiesConfig.advancedSearchMobilePopupBackgroundClass
      "
    >
      <template #content>
        <advanced-search
          v-if="isExtendedFilterPopupVisible"
          v-model:filters="filters"
          v-model:is-available="searchInput.is_available"
          :search-type="searchType"
          @on-open-all-styles-popup="allStylesConfig.isActive = true"
          @on-searched="onSearch()"
        />
      </template>
    </popup>

    <popup v-if="allStylesConfig.isActive" v-model:config="allStylesConfig">
      <template #content>
        <all-property-types-styles
          v-model:selected-type-styles="filters.property_types"
          @close="allStylesConfig.isActive = false"
        />
      </template>
    </popup>

    <popup
      v-if="applicantProfilesPopupConfig.isActive"
      v-model:config="applicantProfilesPopupConfig"
      custom-width="500px"
    >
      <template #content>
        <applicant-profiles
          :search-type="searchType"
          @on-selected-new-applicant="onSelectedNewApplicant()"
        />
      </template>
    </popup>
  </lazy-library-container>

  <section
    v-else-if="variants.v2 === variant"
    class="tw-mt-28 tw-grid tw-grid-cols-12 tw-items-start tw-gap-6 tw-pt-20"
    style="background-color: var(--c-section_primary)"
  >
    <div class="tw-order-1 tw-col-span-12 xl:tw-col-span-4">
      <lazy-library-properties-search-box
        v-model:filters="filters"
        v-model:locations="locations"
        v-model:search-input="searchInput"
        :search-type="searchType"
        :version="variant"
        @on-search="onSearch()"
        @open-extended-popup="openExtendedPopup()"
      />

      <div
        v-if="this?.advancedSearchConfig?.isActive"
        class="tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-h-full tw-w-full tw-bg-black tw-opacity-40"
      ></div>

      <div
        v-if="sidebarComponents && sidebarComponents.length"
        class="tw-mt-4 tw-hidden tw-pb-8 xl:tw-block"
      >
        <component
          :is="is"
          key="property-sidebar"
          key-prefix="property-sidebar"
          :specific-components="sidebarComponents"
        />
      </div>
    </div>

    <div class="tw-order-2 tw-col-span-12 xl:tw-col-span-8">
      <div v-if="filterSummary">
        <div class="tw-flex tw-items-center tw-justify-between tw-gap-4">
          <p>{{ filterSummary }}</p>

          <div
            v-if="isApplicantExist"
            class="tw-me-3 tw-flex tw-cursor-pointer tw-items-center"
            @click="onClickedProfile()"
          >
            <span
              class="nc-icon-profile-user-1 tw-me-2"
              style="font-size: 24px"
            >
            </span>
            <p class="tw-mr-2 tw-truncate tw-capitalize">
              {{ contactSession.name }}
            </p>
          </div>

          <button
            v-if="!isApplicantExist"
            class="n-primary"
            @click="setupHeadsUpAlerts()"
          >
            Heads Up Property Alerts
          </button>
          <button
            v-if="isApplicantExist"
            class="n-primary"
            @click="updateHeadsUpAlerts()"
          >
            Update Heads Up Alert
          </button>
        </div>
        <div class="tw-py-4"></div>

        <div class="tw-py-5" v-if="isLoading">
          <loader />
        </div>

        <div
          v-else-if="
            !isLoading && (highlightedProperties.length || properties.length)
          "
        >
          <div v-if="locations.length > 0" class="row g-0 mb-3">
            <div class="col-12">
              <location-boxes
                v-model:locations="locations"
                :is-first-location-excluded="true"
              />
            </div>
          </div>

          <template v-if="highlightedProperties.length">
            <div
              v-if="highlightedProperties.length > 0"
              class="row g-0 mb-5 d-flex flex-row justify-content-between"
            >
              <div class="col-md-8 col-12 tw-mb-4 md:tw-mb-0">
                <lazy-library-title
                  tag="h1"
                  :text="`New matches: ${newMatchesPropertyCount} properties`"
                >
                </lazy-library-title>
              </div>
              <div class="col-md-4 col-xl-2 col-12 d-flex justify-content-end">
                <lazy-library-properties-sort-box
                  v-model:filter-criteria="filterCriteria"
                  @on-sort="sort()"
                />
              </div>
            </div>
            <lazy-library-properties-page-list
              v-if="highlightedProperties.length > 0"
              :component="component"
              :properties="highlightedProperties"
            />
          </template>

          <div
            class="properties-match-card tw-flex tw-items-center tw-justify-between tw-p-4"
          >
            <h3 v-if="highlightedProperties.length === 0">
              {{ count }} Results
            </h3>
            <h3
              v-else-if="highlightedProperties.length > 0 && properties.length"
            >
              Existing matches:
              {{ count }} - {{ highlightedProperties.length }} Results
            </h3>

            <library-properties-sort-box
              v-if="highlightedProperties.length === 0"
              v-model:filter-criteria="filterCriteria"
              version="v2"
              @on-sort="sort()"
            />
          </div>

          <lazy-library-properties-page-list
            theme="theme4"
            :component="component"
            :properties="properties"
          />
          <div
            v-if="isPropertiesGridLoadMoreActive"
            class="tw-flex tw-items-center tw-justify-center tw-py-8"
          >
            <button class="n-secondary" @click="loadMore()">LOAD MORE</button>
          </div>
        </div>

        <div v-else-if="properties && properties.length === 0" class="py-5">
          <lazy-theme4-base-not-found
            title="Unfortunately, there are no properties matching your search criteria."
          >
            <template #description>
              Please
              <nuxt-link
                to="/heads-up-alerts/register"
                :external="true"
                class="n-link"
                ><u class="unknown-user-text text"
                  >setup a Heads Up property alert</u
                ></nuxt-link
              >
              to be kept up to date in the future with new suggested properties
              that are based around your specific criteria.
            </template>
          </lazy-theme4-base-not-found>
        </div>

        <advanced-search
          v-if="!isMobile && isExtendedFilterPopupVisible"
          v-model:filters="filters"
          v-model:is-available="searchInput.is_available"
          :search-type="searchType"
          @on-open-all-styles-popup="allStylesConfig.isActive = true"
          theme="theme4"
          @open-extended-popup="openExtendedPopup()"
        />

        <popup
          v-if="isMobile"
          :config="advancedSearchConfig"
          custom-width="auto"
          :custom-wrapper-class="
            propertiesConfig.advancedSearchMobilePopupBackgroundClass
          "
        >
          <template #content>
            <advanced-search
              v-if="isExtendedFilterPopupVisible"
              v-model:filters="filters"
              v-model:is-available="searchInput.is_available"
              :search-type="searchType"
              @on-open-all-styles-popup="allStylesConfig.isActive = true"
              @on-searched="onSearch()"
              theme="theme4"
            />
          </template>
        </popup>

        <popup v-if="allStylesConfig.isActive" v-model:config="allStylesConfig">
          <template #content>
            <all-property-types-styles
              v-model:selected-type-styles="filters.property_types"
              @close="allStylesConfig.isActive = false"
              variant="v2"
            />
          </template>
        </popup>

        <popup
          v-if="applicantProfilesPopupConfig.isActive"
          v-model:config="applicantProfilesPopupConfig"
          custom-width="500px"
        >
          <template #content>
            <applicant-profiles
              :search-type="searchType"
              @on-selected-new-applicant="onSelectedNewApplicant()"
            />
          </template>
        </popup>
      </div>
    </div>

    <div class="tw-order-3 tw-col-span-12">
      <div
        v-if="sidebarComponents && sidebarComponents.length"
        class="tw-mt-4 tw-block tw-pb-8 xl:tw-hidden"
      >
        <component
          :is="`${theme}-AvailableComponentsForSidebar`"
          key="property-sidebar"
          key-prefix="property-sidebar"
          :specific-components="sidebarComponents"
        />
      </div>
    </div>
  </section>

  <section
    v-else-if="variants.v3 === variant"
    class="tw-mt-28 tw-grid tw-grid-cols-12 tw-items-start tw-gap-6 tw-pt-20"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      v-if="isExtendedFilterPopupVisible"
      class="tw-fixed tw-left-0 tw-top-0 tw-z-20 tw-h-screen tw-w-full tw-bg-black tw-opacity-40"
    ></div>
    <div class="tw-col-span-12 tw-w-full">
      <div class="tw-block xl:tw-hidden">
        <lazy-library-properties-search-box
          v-model:filters="filters"
          v-model:locations="locations"
          v-model:search-input="searchInput"
          :search-type="searchType"
          @on-search="onSearch()"
          @open-extended-popup="openExtendedPopup()"
          :buy="vars.buyBool"
          :rent="vars.rentBool"
          version="v2"
        />
      </div>
      <div class="tw-hidden xl:tw-block">
        <lazy-library-properties-search-box
          v-model:filters="filters"
          v-model:locations="locations"
          v-model:search-input="searchInput"
          :search-type="searchType"
          @on-search="onSearch()"
          @open-extended-popup="openExtendedPopup()"
          :agent-type="vars.agentType"
          version="v3"
        />
      </div>
    </div>

    <div class="tw-col-span-12">
      <div v-if="filterSummary">
        <div class="tw-flex tw-items-center tw-justify-between tw-gap-4">
          <p>{{ filterSummary }}</p>

          <div
            v-if="isApplicantExist"
            class="tw-me-3 tw-flex tw-cursor-pointer tw-items-center"
            @click="onClickedProfile()"
          >
            <span
              class="nc-icon-profile-user-1 tw-me-2"
              style="font-size: 24px"
            >
            </span>
            <p class="tw-mr-2 tw-truncate tw-capitalize">
              {{ contactSession.name }}
            </p>
          </div>

          <button
            v-if="!isApplicantExist"
            class="n-primary"
            @click="setupHeadsUpAlerts()"
          >
            Heads Up Property Alerts
          </button>
          <button
            v-if="isApplicantExist"
            class="n-primary"
            @click="updateHeadsUpAlerts()"
          >
            Update Heads Up Alert
          </button>
        </div>
        <div class="tw-py-4">
          <hr />
        </div>

        <div class="tw-py-5" v-if="isLoading">
          <loader />
        </div>

        <div
          v-else-if="
            !isLoading && (highlightedProperties.length || properties.length)
          "
        >
          <div v-if="locations.length > 0" class="row g-0 mb-3">
            <div class="col-12">
              <location-boxes
                v-model:locations="locations"
                :is-first-location-excluded="true"
              />
            </div>
          </div>
          <div v-if="vars.isFineAndCountryBool && filters.min_price >= 600000">
            <fine-and-country
              :static="{
                variant: 'v3',
                bgColor: 'section_black',
                logo: 'https://files.beercocks.neuronwebsites.co.uk/fineandcountry-pu6pe6oyu.webp',
                title:
                  '<p class=\'n-title-primary\'>This search criteria is mainly covered by our <span class=\'n-title-tertiary\'> Fine and Country </span> branch. <span class=\'n-title-tertiary\'> Go to their branch page to find out more. </span> </p>',
                buttons: [
                  {
                    label: 'Search Fine and Country',
                    link: '#',
                  },
                  {
                    label: 'Register with Fine and Country',
                    link: '#',
                  },
                  {
                    label: 'View Fine and Country',
                    link: '#',
                  },
                ],
              }"
            />
          </div>

          <template v-if="highlightedProperties.length">
            <div
              v-if="highlightedProperties.length > 0"
              class="row g-0 mb-5 d-flex flex-row justify-content-between"
            >
              <div class="col-md-8 col-12 tw-mb-4 md:tw-mb-0">
                <lazy-library-title
                  tag="h1"
                  :text="`New matches: ${newMatchesPropertyCount} properties`"
                >
                </lazy-library-title>
              </div>
              <div class="col-md-4 col-xl-2 col-12 d-flex justify-content-end">
                <lazy-library-properties-sort-box
                  v-model:filter-criteria="filterCriteria"
                  @on-sort="sort()"
                />
              </div>
            </div>
            <lazy-library-properties-page-list
              v-if="highlightedProperties.length > 0"
              :component="component"
              :properties="highlightedProperties"
            />
          </template>

          <div
            class="properties-match-card tw-flex tw-items-center tw-justify-between"
          >
            <h3 v-if="highlightedProperties.length === 0">
              {{ count }} Results
            </h3>
            <h3
              v-else-if="highlightedProperties.length > 0 && properties.length"
            >
              Existing matches:
              {{ count }} - {{ highlightedProperties.length }} Results
            </h3>

            <library-properties-sort-box
              v-if="highlightedProperties.length === 0"
              v-model:filter-criteria="filterCriteria"
              version="v2"
              @on-sort="sort()"
            />
          </div>
          <lazy-library-properties-page-list
            theme="theme4"
            :component="component"
            :properties="properties"
            :columns="vars.columnsNumber"
            :pre-market-property-feature="vars.preMarketPropertyFeatureBool"
          />
          <div
            v-if="isPropertiesGridLoadMoreActive"
            class="tw-flex tw-items-center tw-justify-center tw-py-8"
          >
            <button class="n-secondary" @click="loadMore()">LOAD MORE</button>
          </div>
        </div>

        <div v-else-if="properties && properties.length === 0" class="py-5">
          <lazy-theme4-base-not-found
            title="Unfortunately, there are no properties matching your search criteria."
          >
            <template #description>
              Please
              <nuxt-link
                to="/heads-up-alerts/register"
                :external="true"
                class="n-link"
                ><u class="unknown-user-text text"
                  >setup a Heads Up property alert</u
                ></nuxt-link
              >
              to be kept up to date in the future with new suggested properties
              that are based around your specific criteria.
            </template>
          </lazy-theme4-base-not-found>
        </div>

        <advanced-search
          v-if="!isMobile && isExtendedFilterPopupVisible"
          v-model:filters="filters"
          v-model:is-available="searchInput.is_available"
          :search-type="searchType"
          @on-open-all-styles-popup="allStylesConfig.isActive = true"
          theme="theme4"
          @open-extended-popup="openExtendedPopup()"
        />

        <popup
          v-if="isMobile"
          :config="advancedSearchConfig"
          custom-width="auto"
          :custom-wrapper-class="
            propertiesConfig.advancedSearchMobilePopupBackgroundClass
          "
        >
          <template #content>
            <advanced-search
              v-if="isExtendedFilterPopupVisible"
              v-model:filters="filters"
              v-model:is-available="searchInput.is_available"
              :search-type="searchType"
              @on-open-all-styles-popup="allStylesConfig.isActive = true"
              @on-searched="onSearch()"
              theme="theme4"
            />
          </template>
        </popup>

        <popup v-if="allStylesConfig.isActive" v-model:config="allStylesConfig">
          <template #content>
            <all-property-types-styles
              v-model:selected-type-styles="filters.property_types"
              @close="allStylesConfig.isActive = false"
              variant="v2"
            />
          </template>
        </popup>

        <popup
          v-if="applicantProfilesPopupConfig.isActive"
          v-model:config="applicantProfilesPopupConfig"
          custom-width="500px"
        >
          <template #content>
            <applicant-profiles
              :search-type="searchType"
              @on-selected-new-applicant="onSelectedNewApplicant()"
            />
          </template>
        </popup>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import CriteriaPreferencesConstants from '~/units/properties/constants/criteriaPreferences.constants';
import Locations from '~/components/common/Locations.vue';
import MultipleInputGroup from '~/components/common/MultipleInputGroup.vue';
import {
  PFilterBillsIncludes,
  PFilterFurnishing,
  PFilterHouseFlatShare,
  PFilterNewHomes,
  PFilterPetsAllowed,
  PFilterRetirementHomes,
  PFilterSearchType,
  PFilterSharedOwnership,
  PFilterShortLet,
  PFilterSortOptions,
} from '~/units/properties/types';

import type {
  Location,
  LocationComponent,
  PropertiesFilter,
  PropertiesFilterRouteQueryKeys,
  PropertiesQuery,
  PropertyItemComponent,
} from '~/units/properties/types';
import PropertiesListBox from '~/components/theme2/components/PropertiesListBox.vue';

import { usePropertiesStore } from '~/units/properties/stores';
import { FetchModes } from '~/units/core/types';
import AdvancedSearch from '~/components/common/properties/AdvancedSearch.vue';
import Popup from '~/components/common/popup/Popup.vue';
import AllPropertyTypesStyles from '~/components/common/properties/AllPropertyTypeStyle.vue';
import Loader from '~/components/common/Loader.vue';
import { useCoreStore } from '~/units/core/store';
import { useApplicantsStore } from '~/units/applicants/store';
import LocationBoxes from '~/components/common/LocationBoxes.vue';
import ApplicantProfiles from '~/components/common/properties/ApplicantProfiles.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { useWebsiteStore } from '~/units/website/store';
import FineAndCountry from '~/components/theme4/components/FineAndCountry.vue';

export default defineNuxtComponent({
  name: 'LibraryProperties',
  components: {
    NcButton,
    ApplicantProfiles,
    LocationBoxes,
    Loader,
    AllPropertyTypesStyles,
    Popup,
    AdvancedSearch,
    PropertiesListBox,
    MultipleInputGroup,
    Locations,
    FineAndCountry,
  },

  mixins: [ComponentMixin],

  data() {
    return {
      allStylesConfig: {
        isActive: false,
      },

      applicantProfilesPopupConfig: {
        isActive: false,
      },

      searchInput: {
        keyword: '',
        mile: 0.25,

        is_available: false,
      },

      filterSummary: '',

      filterCriteria: {
        sort: PFilterSortOptions.SUGGESTED,
      },

      advancedSearchConfig: {
        isActive: false,
      },

      filters: {
        min_bedroom: null,
        max_bedroom: null,
        min_bathroom: null,
        max_bathroom: null,
        min_reception: null,
        max_reception: null,
        min_price: null,
        max_price: null,

        new_homes: PFilterNewHomes.INCLUDE,
        shared_ownership: PFilterSharedOwnership.INCLUDE,

        must_haves: [],
        retirement_homes: PFilterRetirementHomes.INCLUDE,
        property_types: [],

        // lettings
        short_let: PFilterShortLet.INCLUDE,
        house_flat_share: PFilterHouseFlatShare.INCLUDE,
        pets_allowed: PFilterPetsAllowed.INCLUDE,
        bills_included: PFilterBillsIncludes.INCLUDE,
        furnishing: PFilterFurnishing.ANY,
      },
      locations: [],

      isLoaded: false,

      isExtendedFilterPopupVisible: false,

      searchTypes: PFilterSearchType,

      isLoading: false,

      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
      },
    };
  },

  computed: {
    ...mapState(usePropertiesStore, [
      'propertiesGridList',
      'propertiesGridCount',
      'isPropertiesGridLoadMoreActive',
      'propertiesGridInitialRouteQuery',
      'propertiesGridInitialLoadConfig',
      'propertiesGridAwaitingRouteQuery',
      'highlightedPropertiesGridList',
    ]),

    ...mapState(useCoreStore, ['isMobile']),
    ...mapState(useWebsiteStore, [
      'propertiesConfig',
      'theme',
      'sidebarComponents',
    ]),

    ...mapState(useApplicantsStore, [
      'loggedContact',
      'applicant',
      'getApplicantByType',
    ]),

    isApplicantExist() {
      return this.getApplicantByType(this.searchType);
    },

    newMatchesPropertyCount() {
      if (this.highlightedPropertiesGridList.length < 9) {
        return this.highlightedPropertiesGridList.length;
      }
      return this.propertiesGridCount - this.properties.length;
    },

    properties(): PropertyItemComponent[] {
      return this.propertiesGridList;
    },

    highlightedProperties(): PropertyItemComponent[] {
      return this.highlightedPropertiesGridList;
    },

    count() {
      return this.propertiesGridCount;
    },

    searchType(): PFilterSearchType {
      return getVariable(
        this.component,
        'search_type-text',
      ) as PFilterSearchType;
    },

    variant() {
      return useVariant(this.component);
    },

    is() {
      return defineAsyncComponent(
        () =>
          import(
            `~/components/${this.theme}/AvailableComponentsForSidebar.vue`
          ),
      );
    },
  },

  async setup({ component }) {
    const route = useRoute();
    const searchType = getVariable(
      component,
      'search_type-text',
    ) as PFilterSearchType;

    const applicantsStore = useApplicantsStore();

    await applicantsStore.fetchApplicantBySessionAndType(searchType);

    const applicant = applicantsStore.getApplicantByType(searchType);

    const plainQuery = { ...route.query };
    Object.keys(plainQuery).forEach((key) => {
      if (key.includes('[]') || !key) {
        delete plainQuery[key];
      }
    });

    const isAvailableDefaultValue =
      (getVariable(
        component,
        'is_available_filter_enabled_default-number',
      ) as number) === 1;

    const fetchByQuery = async () => {
      const store = usePropertiesStore();

      store.setInitialLoadConfig({
        is_available: isAvailableDefaultValue,
      });

      const filter: PropertiesFilter = {};
      const query: PropertiesQuery = {
        is_available: isAvailableDefaultValue,
      };
      let order: PFilterSortOptions = PFilterSortOptions.SUGGESTED;
      try {
        Object.keys(route.query).forEach((item) => {
          const key = item as PropertiesFilterRouteQueryKeys;
          const value = route.query[key] as string;

          if (value) {
            if (key === 'is_available') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              query[key] = value;
            } else if (key === 'sort') {
              order = value as PFilterSortOptions;
            } else {
              filter[key] = JSON.parse(value);
            }
          }
        });
      } catch (e) {
        // just to escape errors!
      }

      await store.fetch(FetchModes.INIT, 9, order, searchType, query, filter);
    };

    let isAlreadyRedirected;

    if (Object.keys(plainQuery).length > 0) {
      await fetchByQuery();
      isAlreadyRedirected = true;
    } else if (applicant) {
      const store = usePropertiesStore();

      store.setInitialLoadConfig({
        is_available: isAvailableDefaultValue,
      });

      const filter: PropertiesFilter = {};
      const query: PropertiesQuery = {
        is_available: isAvailableDefaultValue,
      };

      const order: PFilterSortOptions = PFilterSortOptions.SUGGESTED;

      const criteria = applicant.criteria;

      const criteriaKeys = [
        'min_bedroom',
        'max_bedroom',
        'min_bathroom',
        'max_bathroom',
        'min_reception',
        'max_reception',
        'min_price',
        'max_price',
        'locations',
        'property_types',
        'new_homes',
        'retirement_homes',
        'shared_ownership',
        'short_let',
        'house_flat_share',
        'pets_allowed',
        'bills_included',
        'must_haves',
        'furnishing',
      ];

      criteriaKeys.forEach((criteriaKey: string) => {
        if (criteria[criteriaKey]) {
          filter[criteriaKey] = criteria[criteriaKey];
        }
      });

      await store.fetch(
        FetchModes.LOAD_BY_DATA,
        9,
        order,
        searchType,
        query,
        filter,
      );
    } else if (!applicant) {
      await fetchByQuery();
    }

    return {
      isAlreadyRedirected,
    };
  },

  async created() {
    if (process.client) {
      await this.handleAwaitingRouteQuery(
        this.propertiesGridAwaitingRouteQuery,
      );
    }
    this.onAfterSearch();
  },

  methods: {
    ...mapActions(usePropertiesStore, ['fetch', 'handleAwaitingRouteQuery']),

    onAfterSearch() {
      this.parseInitialRouteQuery();
      this.computeFilterSummary();
      this.loadInitialConfig();

      const coreStore = useCoreStore();
      coreStore.updateMetaTagForce('title', this.filterSummary);
    },

    async onSelectedNewApplicant() {
      const isAvailableDefaultValue =
        (getVariable(
          this.component,
          'is_available_filter_enabled_default-number',
        ) as number) === 1;

      const store = usePropertiesStore();
      const applicantsStore = useApplicantsStore();
      const applicant = applicantsStore.applicant;

      store.setInitialLoadConfig({
        is_available: isAvailableDefaultValue,
      });

      const filter: PropertiesFilter = {};
      const query: PropertiesQuery = {
        is_available: isAvailableDefaultValue,
      };

      const order: PFilterSortOptions = PFilterSortOptions.SUGGESTED;

      const criteria = applicant.criteria;

      const criteriaKeys = [
        'min_bedroom',
        'max_bedroom',
        'min_bathroom',
        'max_bathroom',
        'min_reception',
        'max_reception',
        'min_price',
        'max_price',
        'locations',
        'property_types',
        'new_homes',
        'retirement_homes',
        'shared_ownership',
        'short_let',
        'house_flat_share',
        'pets_allowed',
        'bills_included',
        'must_haves',
        'furnishing',
      ];

      criteriaKeys.forEach((criteriaKey: string) => {
        if (criteria[criteriaKey]) {
          filter[criteriaKey] = criteria[criteriaKey];
        }
      });

      await store.fetch(
        FetchModes.LOAD_BY_DATA,
        9,
        order,
        this.searchType,
        query,
        filter,
      );
      this.applicantProfilesPopupConfig.isActive = false;
      this.onAfterSearch();
    },

    onClickedProfile() {
      this.applicantProfilesPopupConfig.isActive = true;
    },

    openExtendedPopup() {
      this.isExtendedFilterPopupVisible = !this.isExtendedFilterPopupVisible;
      this.advancedSearchConfig.isActive = !this.advancedSearchConfig.isActive;
    },

    parseInitialRouteQuery() {
      Object.keys(this.propertiesGridInitialRouteQuery).forEach(
        (filterKey: string) => {
          const key = filterKey as PropertiesFilterRouteQueryKeys;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const value = this.propertiesGridInitialRouteQuery[key];

          if (key === 'locations') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            this.locations = value as Location[];
            if (this.locations.length) {
              const firstLocation: Location = this.locations[0];
              this.searchInput.mile = firstLocation.distance;
              this.searchInput.keyword = firstLocation.location;
            }
          } else if (key === 'sort') {
            this.filterCriteria.sort = value;
          } else if (value && key in this.filters) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            this.filters[key] = value;
          } else if (value && key === 'is_available') {
            if (key === 'is_available') {
              this.searchInput.is_available = value === 'true' || value;
            }
          }
        },
      );
    },

    loadInitialConfig() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (!('is_available' in this.propertiesGridInitialRouteQuery)) {
        this.searchInput.is_available =
          this.propertiesGridInitialLoadConfig.is_available;
      }
    },

    mapLocationsForSearch() {
      return this.locations.map(
        (location: LocationComponent, index: number) => {
          return {
            bounds: location.bounds,
            lat: location.lat,
            lng: location.lng,
            location: location.location,
            location_type: location.location_type,
            place_id: location.place_id,
            distance: index === 0 ? this.searchInput.mile : location.distance,
          };
        },
      );
    },

    computeFilterSummary() {
      const searchType = (() => {
        if (this.searchType === PFilterSearchType.SALES) {
          return 'Properties For Sale';
        } else if (this.searchType === PFilterSearchType.LETTINGS) {
          return 'Properties To Rent';
        }
        return '';
      })();
      const location = (() => {
        if (this.searchInput.keyword) {
          return ` in ${this.searchInput.keyword}`;
        }
        return '';
      })();
      const mile = (() => {
        if (this.searchInput.mile && location) {
          return `, within ${this.searchInput.mile} miles`;
        }
        return '';
      })();

      const locationPart = (() => {
        if (location) {
          return `${searchType}${location}${mile}`;
        } else if (searchType) {
          return `${searchType}`;
        }
        return '';
      })();

      const pricePart = (() => {
        const prices =
          this.searchType === PFilterSearchType.SALES
            ? CriteriaPreferencesConstants.salesPrices
            : CriteriaPreferencesConstants.lettingsPrices;

        const { display: minPrice } = prices.find(
          (price) => price.value === this.filters.min_price,
        ) || { display: 0, value: 0 };
        const { display: maxPrice } = prices.find(
          (price) => price.value === this.filters.max_price,
        ) || { display: 0, value: 0 };

        if (this.filters.min_price && this.filters.max_price) {
          return `, ${minPrice} - ${maxPrice}`;
        } else if (this.filters.min_price) {
          return `, at least ${minPrice}`;
        } else if (this.filters.max_price) {
          return `, up to ${maxPrice}`;
        }
        return '';
      })();

      const bedroomPart = (() => {
        if (this.filters.min_bedroom && this.filters.max_bedroom) {
          return `, ${this.filters.min_bedroom} - ${this.filters.max_bedroom} bed`;
        } else if (this.filters.min_bedroom) {
          return `, at least ${this.filters.min_bedroom} bed`;
        } else if (this.filters.max_bedroom) {
          return `, up to ${this.filters.max_bedroom} bed`;
        }
        return '';
      })();

      this.filterSummary = `${locationPart}${pricePart}${bedroomPart}`;
    },

    async onSearch() {
      this.advancedSearchConfig.isActive = false;
      this.isExtendedFilterPopupVisible = false;
      this.isLoading = true;
      const store = usePropertiesStore();
      await store.fetch(
        FetchModes.SEARCH,
        9,
        this.filterCriteria.sort,
        this.searchType,
        {
          is_available: this.searchInput.is_available,
        },
        {
          locations: this.mapLocationsForSearch(),
          ...this.filters,
        },
      );
      this.isLoading = false;
      this.computeFilterSummary();
      const coreStore = useCoreStore();
      coreStore.updateMetaTagForce('title', this.filterSummary);
    },

    async loadMore() {
      const store = usePropertiesStore();
      await store.fetch(
        FetchModes.LOAD_MORE,
        9,
        this.filterCriteria.sort,
        this.searchType,
        {
          is_available: this.searchInput.is_available,
        },
        {
          locations: this.mapLocationsForSearch(),
          ...this.filters,
        },
      );
    },

    async sort() {
      const store = usePropertiesStore();
      this.isLoading = true;
      await store.fetch(
        FetchModes.SORT,
        9,
        this.filterCriteria.sort,
        this.searchType,
        {
          is_available: this.searchInput.is_available,
        },
        {
          locations: this.mapLocationsForSearch(),
          ...this.filters,
        },
      );
      this.isLoading = false;
    },

    async setupHeadsUpAlerts() {
      const type = (() => {
        if (this.searchType === PFilterSearchType.LETTINGS) {
          return 'rent';
        }
        return 'buy';
      })();

      await this.redirectTo(
        {
          path: `/heads-up-alerts/register/${type}`,
        },
        true,
      );
    },

    async updateHeadsUpAlerts() {
      if (this.applicant) {
        const applicantKey = this.applicant?.applicant_key;

        await this.redirectTo({
          path: `/heads-up-alerts/profiles/${applicantKey}`,
        });
      }
    },
  },
});
</script>

<style scoped>
.properties-filter-item {
  height: 55px;
  background-color: var(--white);
}
</style>
