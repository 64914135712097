<template>
  <div class="tw-relative tw-w-full">
    <NuxtImg
      v-if="coverPhoto"
      :src="coverPhoto"
      :alt="title"
      class="tw-w-full"
    />

    <iframe
      v-else-if="!coverPhoto && !libraryIframe"
      class="video tw-h-[320px] md:tw-h-[460px] lg:tw-h-[540px]"
      width="100%"
      :src="videoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>

    <library-iframe
      v-else-if="libraryIframe"
      :src="videoUrl"
      :w-ratio="wRatio"
      :h-ratio="hRatio"
    />

    <div
      v-if="isPopup"
      class="tw-absolute tw-top-0 tw-z-10 tw-h-full tw-w-full"
      @click="isVisible = true"
    >
      <button
        class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
      >
        <svg
          width="80"
          height="80"
          viewBox="0 0 162 162"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="81" cy="81" r="81" fill="white" />
          <polygon points="65,45 115,81 65,117" fill="black" />
        </svg>
      </button>
    </div>
    <library-popups-video-popup
      v-if="isPopup"
      :video-url="videoUrl"
      v-model:is-visible="isVisible"
    />
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'LibraryVideoEmbed',

  props: {
    videoUrl: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
    },
    height: {
      required: false,
      type: Number,
      default: 460,
    },
    isPopup: {
      required: false,
      type: Boolean,
      default: false,
    },
    coverPhoto: {
      required: false,
      type: [String, Boolean],
      default: false,
    },

    hRatio: {
      required: false,
      type: Number,
      default: 2,
    },
    wRatio: {
      required: false,
      type: Number,
      default: 3,
    },

    libraryIframe: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },
});
</script>
